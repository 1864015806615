const initialState = {
    loading: false,
    timeline: [],
    redirect: false,
};

const Timeline = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_TIMELINE_REQUEST':
            return { ...state, loading: true };
        case 'GET_TIMELINE_SUCCESS':
            return { ...state, loading: false, timeline: action.timeline };
        case 'GET_TIMELINE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'TIMELINE_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'TIMELINE_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, timeline: state.timeline.concat(action.timeline) };
        case 'TIMELINE_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'TIMELINE_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'TIMELINE_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: true, timeline: state.timeline.map(timeline => timeline._id === action.timeline._id ? action.timeline : timeline) };
        case 'TIMELINE_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'TIMELINE_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'TIMELINE_DELETE_SUCCESS':
            return { ...state, loading: false, timeline: state.timeline.filter(timeline => timeline._id !== action.timeline) };
        case 'TIMELINE_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default Timeline;