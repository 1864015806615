import axios from "axios";
import { toast } from 'react-hot-toast';

const API_URL = process.env.REACT_APP_API_HOST;


export const fetchCoverPhoto = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_COVERPHOTO_REQUEST' });
            const response = await axios.get(`${API_URL}/cover-photo`, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'GET_COVERPHOTO_SUCCESS', coverPhoto: response.data });
        } catch (error) {
            dispatch({ type: 'GET_COVERPHOTO_FAILURE', error: error.message });
        }
    }
};

export const createCoverPhoto = (coverPhoto) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'COVERPHOTO_CREATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/cover-photo`, coverPhoto, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'COVERPHOTO_CREATE_SUCCESS', coverPhoto: data });
            toast.success('CoverPhoto Created successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'COVERPHOTO_CREATE_FAILURE', error: error.message });
        }
    }
}

export const updateCoverPhoto = (id, coverPhoto) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'COVERPHOTO_UPDATE_REQUEST' });
            const { data } = await axios.put(`${API_URL}/cover-photo/${id}`, coverPhoto, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'COVERPHOTO_UPDATE_SUCCESS', coverPhoto: data });
            toast.success('CoverPhoto Updated successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'COVERPHOTO_UPDATE_FAILURE', error: error.message });
        }
    }
}


export const deleteCoverPhoto = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'COVERPHOTO_DELETE_REQUEST' });
            const config = {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            }
            const { data } = await axios.delete(`${API_URL}/cover-photo/${id}`, config);
            dispatch({ type: 'COVERPHOTO_DELETE_SUCCESS', coverPhoto: data._id });
            toast.success('CoverPhoto Deleted successfully!');
        } catch (error) {
            dispatch({ type: 'COVERPHOTO_DELETE_FAILURE', error: error.message });
        }
    }
}

export const setRedirect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
}