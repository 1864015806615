import axios from 'axios';
import { toast } from 'react-hot-toast';


const API_URL = process.env.REACT_APP_API_HOST

export const getPhotos = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'PHOTO_REQUEST' });
            const { data } = await axios.get(`${API_URL}/photo`, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'PHOTO_SUCCESS', photo: data });
        }
        catch (error) {
            dispatch({ type: 'PHOTO_FAILURE', error: error.message });
        }
    }
}

export const createPhotos = (photo) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'PHOTO_CREATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/photo`, photo, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            if (data.type === 'update') {
                dispatch({ type: 'PHOTO_UPDATE_SUCCESS', photo: data.resposnse });
                toast.success('Photo Updated successfully!');
            } else {
                dispatch({ type: 'PHOTO_CREATE_SUCCESS', photo: data.resposnse });
                toast.success('Photo Added successfully!');
            }
        } catch (error) {
            toast.error(error.message);
            dispatch({ type: 'PHOTO_CREATE_FAILURE', error: error.message });
        }
    }
}

export const updatePhotos = (id, photo) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'PHOTO_UPDATE_REQUEST' });
            const { data } = await axios.put(`${API_URL}/photo/${id}`, photo, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'PHOTO_UPDATE_SUCCESS', photo: data });
            toast.success('Photo Updated successfully!');
        } catch (error) {
            toast.error(error.message);
            dispatch({ type: 'PHOTO_UPDATE_FAILURE', error: error.message });
        }
    }
}

export const deletePhotos = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'PHOTO_DELETE_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('xat')
                }
            }
            const { data } = await axios.delete(`${API_URL}/photo/${id}`, config);
            dispatch({ type: 'PHOTO_DELETE_SUCCESS', photo: data });
            toast.success('Photo Deleted successfully!');
        } catch (error) {
            dispatch({ type: 'PHOTO_DELETE_FAILURE', error: error.message });
        }
    }
}

export const setRedirect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
}