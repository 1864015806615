const initialState = {
    loading: false,
    photo: [],
    redirect: false,
};

const Photo = (state = initialState, action) => {
    switch (action.type) {
        case 'PHOTO_REQUEST':
            return { ...state, loading: true };
        case 'PHOTO_SUCCESS':
            return { ...state, loading: false, photo: action.photo };
        case 'PHOTO_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'PHOTO_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'PHOTO_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, photo: state.photo.concat(action.photo) };
        case 'PHOTO_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'PHOTO_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'PHOTO_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: true, photo: state.photo.map(photo => photo._id === action.photo._id ? action.photo : photo) };
        case 'PHOTO_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'PHOTO_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'PHOTO_DELETE_SUCCESS':
            return { ...state, loading: false, photo: state.photo.filter(photo => photo._id !== action.photo) };
        case 'PHOTO_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default Photo;