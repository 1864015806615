const initialState = {
    loading: false,
    hereAboutUs: [],
    redirect: false,
};

const HereAboutUs = (state = initialState, action) => {
    switch (action.type) {
        case 'HERE_ABOUT_US_REQUEST':
            return { ...state, loading: true };
        case 'HERE_ABOUT_US_SUCCESS':
            return { ...state, loading: false, hereAboutUs: action.hereAboutUs };
        case 'HERE_ABOUT_US_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'HERE_ABOUT_US_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'HERE_ABOUT_US_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, hereAboutUs: state.hereAboutUs.concat(action.hereAboutUs) };
        case 'HERE_ABOUT_US_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'HERE_ABOUT_US_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'HERE_ABOUT_US_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: true, hereAboutUs: state.hereAboutUs.map(hereAboutUs => hereAboutUs._id === action.hereAboutUs._id ? action.hereAboutUs : hereAboutUs) };
        case 'HERE_ABOUT_US_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'HERE_ABOUT_US_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'HERE_ABOUT_US_DELETE_SUCCESS':
            return { ...state, loading: false, hereAboutUs: state.hereAboutUs.filter(hereAboutUs => hereAboutUs._id !== action.hereAboutUs) };
        case 'HERE_ABOUT_US_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default HereAboutUs;