import axios from "axios";
import { toast } from 'react-hot-toast';

const API_URL = process.env.REACT_APP_API_HOST;


export const fetchPdfData = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_PDFDATA_REQUEST' });
            const { data } = await axios.get(`${API_URL}/pdf-data`, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'GET_PDFDATA_SUCCESS', pdfData: data });
        } catch (error) {
            dispatch({ type: 'GET_PDFDATA_FAILURE', error: error.message });
        }
    }
};

export const createPdfData = (createPdfData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'PDFDATA_CREATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/pdf-data`, createPdfData, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            if (data.type === 'create') {
                dispatch({ type: 'PDFDATA_CREATE_SUCCESS', pdfData: data.data });
                toast.success('Pdf Data Updated successfully!');
            } else {
                dispatch({ type: 'PDFDATA_UPDATE_SUCCESS', pdfData: data.data });
                toast.success('Pdf Data Updated successfully!');
            }
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'PDFDATA_CREATE_FAILURE', error: error.message });
        }
    }
}
