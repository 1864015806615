import axios from "axios";
import { toast } from 'react-hot-toast';

const API_URL = process.env.REACT_APP_API_HOST;


export const fetchEstimate = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_ESTIMATE_REQUEST' });
            const response = await axios.get(`${API_URL}/estimate/admin/all`, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'GET_ESTIMATE_SUCCESS', estimate: response.data });
        } catch (error) {
            dispatch({ type: 'GET_ESTIMATE_FAILURE', error: error.message });
        }
    }
};

export const createEstimate = (estimate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'ESTIMATE_CREATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/estimate/admin`, estimate, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'ESTIMATE_CREATE_SUCCESS', estimate: data });
            toast.success('Estimate Created successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'ESTIMATE_CREATE_FAILURE', error: error.message });
        }
    }
}

export const updateEstimate = (id, estimate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'ESTIMATE_UPDATE_REQUEST' });
            const { data } = await axios.put(`${API_URL}/estimate/admin/${id}`, estimate, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'ESTIMATE_UPDATE_SUCCESS', estimate: data });
            toast.success('Estimate Updated successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'ESTIMATE_UPDATE_FAILURE', error: error.message });
        }
    }
}

export const updateEstimateModal = (id, estimate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'ESTIMATE_MODAL_UPDATE_REQUEST' });
            const { data } = await axios.put(`${API_URL}/estimate/admin/${id}`, estimate, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'ESTIMATE_MODAL_UPDATE_SUCCESS', estimate: data });
            dispatch({ type: 'SET_REDIRECT' });
            toast.success('Estimate Updated successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'ESTIMATE_MODAL_UPDATE_FAILURE', error: error.message });
        }
    }
}

export const deleteEstimate = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'ESTIMATE_DELETE_REQUEST' });
            const config = {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            }
            const { data } = await axios.delete(`${API_URL}/estimate/admin/${id}`, config);
            dispatch({ type: 'ESTIMATE_DELETE_SUCCESS', estimate: data._id });
            toast.success('Estimate Deleted successfully!');
        } catch (error) {
            dispatch({ type: 'ESTIMATE_DELETE_FAILURE', error: error.message });
        }
    }
}

export const setRedirect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
}