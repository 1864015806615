import CryptoJS from 'crypto-js';

const SECRET_KEY = '1234567890!@#$%^&*()MNBVCXZ';

export const encryptData = (data) => {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
    return encryptedData;
};

export const decryptData = (encryptedData) => {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};
