const initialState = {
    loading: false,
    siteSetting: [],
    redirect: false,
};

const SiteSetting = (state = initialState, action) => {
    switch (action.type) {
        case 'SITE_SETTING_REQUEST':
            return { ...state, loading: true };
        case 'SITE_SETTING_SUCCESS':
            return { ...state, loading: false, siteSetting: action.siteSetting };
        case 'SITE_SETTING_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SITE_SETTING_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'SITE_SETTING_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, siteSetting: state.siteSetting.concat(action.siteSetting) };
        case 'SITE_SETTING_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SITE_SETTING_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'SITE_SETTING_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: true, siteSetting: state.siteSetting.map(siteSetting => siteSetting._id === action.siteSetting._id ? action.siteSetting : siteSetting) };
        case 'SITE_SETTING_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SITE_SETTING_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'SITE_SETTING_DELETE_SUCCESS':
            return { ...state, loading: false, siteSetting: state.siteSetting.filter(siteSetting => siteSetting._id !== action.siteSetting) };
        case 'SITE_SETTING_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default SiteSetting;