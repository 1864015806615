const initialState = {
    loading: false,
    coverPhoto: [],
    redirect: false,
};

const CoverPhoto = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_COVERPHOTO_REQUEST':
            return { ...state, loading: true };
        case 'GET_COVERPHOTO_SUCCESS':
            return { ...state, loading: false, coverPhoto: action.coverPhoto };
        case 'GET_COVERPHOTO_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'COVERPHOTO_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'COVERPHOTO_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, coverPhoto: state.coverPhoto.concat(action.coverPhoto) };
        case 'COVERPHOTO_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'COVERPHOTO_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'COVERPHOTO_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: false, coverPhoto: state.coverPhoto.map(coverPhoto => coverPhoto._id === action.coverPhoto._id ? action.coverPhoto : coverPhoto) };
        case 'COVERPHOTO_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'COVERPHOTO_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'COVERPHOTO_DELETE_SUCCESS':
            return { ...state, loading: false, coverPhoto: state.coverPhoto.filter(coverPhoto => coverPhoto._id !== action.coverPhoto) };
        case 'COVERPHOTO_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default CoverPhoto;