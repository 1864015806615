import axios from 'axios';
import { toast } from 'react-hot-toast';


const API_URL = process.env.REACT_APP_API_HOST

export const getRemovalList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'REMOVAL_LIST_REQUEST' });
            const { data } = await axios.get(`${API_URL}/removal-list`, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'REMOVAL_LIST_SUCCESS', removalList: data });
        }
        catch (error) {
            dispatch({ type: 'REMOVAL_LIST_FAILURE', error: error.message });
        }
    }
}

export const createRemovalList = (removalList) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'REMOVAL_LIST_CREATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/removal-list`, removalList, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'REMOVAL_LIST_CREATE_SUCCESS', removalList: data });
            toast.success('Removal List added successfully!');
        } catch (error) {
            toast.error(error.message);
            dispatch({ type: 'REMOVAL_LIST_CREATE_FAILURE', error: error.message });
        }
    }
}

export const updateRemovalList = (id, removalList) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'REMOVAL_LIST_UPDATE_REQUEST' });
            const { data } = await axios.put(`${API_URL}/removal-list/${id}`, removalList, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'REMOVAL_LIST_UPDATE_SUCCESS', removalList: data });
            toast.success('Removal List Updated successfully!');
        } catch (error) {
            toast.error(error.message);
            dispatch({ type: 'REMOVAL_LIST_UPDATE_FAILURE', error: error.message });
        }
    }
}

export const deleteRemovalList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'REMOVAL_LIST_DELETE_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('xat')
                }
            }
            const { data } = await axios.delete(`${API_URL}/removal-list/${id}`, config);
            dispatch({ type: 'REMOVAL_LIST_DELETE_SUCCESS', removalList: data });
            toast.success('Removal List Deleted successfully!');
        } catch (error) {
            dispatch({ type: 'REMOVAL_LIST_DELETE_FAILURE', error: error.message });
        }
    }
}

export const setRedirect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
}

export const setRedirectRemove = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
}