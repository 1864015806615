const initialState = {
    loading: false,
    whyUsPhoto: [],
    redirect: false,
};

const WhyUsPhoto = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_WHYUSPHOTO_REQUEST':
            return { ...state, loading: true };
        case 'GET_WHYUSPHOTO_SUCCESS':
            return { ...state, loading: false, whyUsPhoto: action.whyUsPhoto };
        case 'GET_WHYUSPHOTO_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'WHYUSPHOTO_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'WHYUSPHOTO_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, whyUsPhoto: state.whyUsPhoto.concat(action.whyUsPhoto) };
        case 'WHYUSPHOTO_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'WHYUSPHOTO_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'WHYUSPHOTO_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: false, whyUsPhoto: state.whyUsPhoto.map(whyUsPhoto => whyUsPhoto._id === action.whyUsPhoto._id ? action.whyUsPhoto : whyUsPhoto) };
        case 'WHYUSPHOTO_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'WHYUSPHOTO_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'WHYUSPHOTO_DELETE_SUCCESS':
            return { ...state, loading: false, whyUsPhoto: state.whyUsPhoto.filter(whyUsPhoto => whyUsPhoto._id !== action.whyUsPhoto) };
        case 'WHYUSPHOTO_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default WhyUsPhoto;