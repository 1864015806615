import axios from 'axios';
import { toast } from 'react-hot-toast';


const API_URL = process.env.REACT_APP_API_HOST

export const getSubdivision = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SUBDIVISION_REQUEST' });
            const { data } = await axios.get(`${API_URL}/subdivision`, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'SUBDIVISION_SUCCESS', subdivision: data });
        }
        catch (error) {
            dispatch({ type: 'SUBDIVISION_FAILURE', error: error.message });
        }
    }
}

export const createSubdivision = (subdivision) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SUBDIVISION_CREATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/subdivision`, subdivision, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'SUBDIVISION_CREATE_SUCCESS', subdivision: data });
            toast.success('Subdivision added successfully!');
        } catch (error) {
            toast.error(error.message);
            dispatch({ type: 'SUBDIVISION_CREATE_FAILURE', error: error.message });
        }
    }
}

export const updateSubdivision = (id, subdivision) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SUBDIVISION_UPDATE_REQUEST' });
            const { data } = await axios.put(`${API_URL}/subdivision/${id}`, subdivision, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'SUBDIVISION_UPDATE_SUCCESS', subdivision: data });
            toast.success('Subdivision Updated successfully!');
        } catch (error) {
            toast.error(error.message);
            dispatch({ type: 'SUBDIVISION_UPDATE_FAILURE', error: error.message });
        }
    }
}

export const deleteSubdivision = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SUBDIVISION_DELETE_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('xat')
                }
            }
            const { data } = await axios.delete(`${API_URL}/subdivision/${id}`, config);
            dispatch({ type: 'SUBDIVISION_DELETE_SUCCESS', subdivision: data });
            toast.success('Subdivision Deleted successfully!');
        } catch (error) {
            dispatch({ type: 'SUBDIVISION_DELETE_FAILURE', error: error.message });
        }
    }
}

export const setRedirect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
}




