const initialState = {
    loading: false,
    propertyUsage: [],
    redirect: false,
};

const PropertyUsage = (state = initialState, action) => {
    switch (action.type) {
        case 'PROPERTY_USAGE_REQUEST':
            return { ...state, loading: true };
        case 'PROPERTY_USAGE_SUCCESS':
            return { ...state, loading: false, propertyUsage: action.propertyUsage };
        case 'PROPERTY_USAGE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'PROPERTY_USAGE_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'PROPERTY_USAGE_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, propertyUsage: state.propertyUsage.concat(action.propertyUsage) };
        case 'PROPERTY_USAGE_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'PROPERTY_USAGE_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'PROPERTY_USAGE_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: true, propertyUsage: state.propertyUsage.map(propertyUsage => propertyUsage._id === action.propertyUsage._id ? action.propertyUsage : propertyUsage) };
        case 'PROPERTY_USAGE_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'PROPERTY_USAGE_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'PROPERTY_USAGE_DELETE_SUCCESS':
            return { ...state, loading: false, propertyUsage: state.propertyUsage.filter(propertyUsage => propertyUsage._id !== action.propertyUsage) };
        case 'PROPERTY_USAGE_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default PropertyUsage;