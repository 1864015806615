const initialState = {
    loading: false,
    prompt: [],
    redirect: false,
};

const Prompt = (state = initialState, action) => {
    switch (action.type) {
        case 'PROMPT_REQUEST':
            return { ...state, loading: true };
        case 'PROMPT_SUCCESS':
            return { ...state, loading: false, prompt: action.prompt };
        case 'PROMPT_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'PROMPT_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'PROMPT_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, prompt: state.prompt.concat(action.prompt) };
        case 'PROMPT_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'PROMPT_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'PROMPT_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: true, prompt: state.prompt.map(prompt => prompt._id === action.prompt._id ? action.prompt : prompt) };
        case 'PROMPT_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'PROMPT_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'PROMPT_DELETE_SUCCESS':
            return { ...state, loading: false, prompt: state.prompt.filter(prompt => prompt._id !== action.prompt) };
        case 'PROMPT_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default Prompt;