import { combineReducers } from 'redux';
import Auth from './authReducers';
import SiteSetting from './siteSettingReducers';
import HereAboutUs from './hereAboutUsReducers';
import TypeOfProperty from './typeOfPropertyReducers';
import ScopeOfWork from './scopeOfWorkReducers';
import PropertyUsage from './propertyUsageReducers';
import SiteType from './siteTypeReducers';
import Estimate from './estimateReducers';
import Photo from './photoReducers';
import RemovalList from './removalListReducers';
import InstallList from './installListReducers';
import Subdivision from './subdivisionReducers';
import Prompt from './promptReducers';
import PdfData from './pdfReducers';
import CoverPhoto from './coverPhotoReducers';
import WhatToExpect from './whatToExpectPhotoReducers';
import WhyUsPhoto from './whyUsPhotoReducers';
import Timeline from './timelineReducers';

const rootReducer = combineReducers({
    Auth,
    SiteSetting,
    HereAboutUs,
    TypeOfProperty,
    ScopeOfWork,
    PropertyUsage,
    SiteType,
    Estimate,
    Photo,
    RemovalList,
    InstallList,
    Subdivision,
    Prompt,
    PdfData,
    CoverPhoto,
    WhatToExpect,
    WhyUsPhoto,
    Timeline
});

export default rootReducer;