import axios from 'axios';
import { toast } from 'react-hot-toast';


const API_URL = process.env.REACT_APP_API_HOST

export const getHereAboutUs = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'HERE_ABOUT_US_REQUEST' });
            const { data } = await axios.get(`${API_URL}/here-about-us`, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'HERE_ABOUT_US_SUCCESS', hereAboutUs: data });
        }
        catch (error) {
            dispatch({ type: 'HERE_ABOUT_US_FAILURE', error: error.message });
        }
    }
}

export const createHereAboutUs = (hereAboutUs) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'HERE_ABOUT_US_CREATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/here-about-us`, hereAboutUs, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'HERE_ABOUT_US_CREATE_SUCCESS', hereAboutUs: data });
            toast.success('Here About Us Created successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'HERE_ABOUT_US_CREATE_FAILURE', error: error.message });
        }
    }
}

export const updateHereAboutUs = (id, hereAboutUs) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'HERE_ABOUT_US_UPDATE_REQUEST' });
            const { data } = await axios.put(`${API_URL}/here-about-us/${id}`, hereAboutUs, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'HERE_ABOUT_US_UPDATE_SUCCESS', hereAboutUs: data });
            toast.success('Here About Us Updated successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'HERE_ABOUT_US_UPDATE_FAILURE', error: error.message });
        }
    }
}

export const deleteHereAboutUs = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'HERE_ABOUT_US_DELETE_REQUEST' });
            const config = {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            }
            const { data } = await axios.delete(`${API_URL}/here-about-us/${id}`, config);
            dispatch({ type: 'HERE_ABOUT_US_DELETE_SUCCESS', hereAboutUs: data });
            toast.success('Here About Us Deleted successfully!');
        } catch (error) {
            dispatch({ type: 'HERE_ABOUT_US_DELETE_FAILURE', error: error.message });
        }
    }
}

export const setRedirect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
}