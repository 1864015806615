import axios from 'axios';
import { toast } from 'react-hot-toast';


const API_URL = process.env.REACT_APP_API_HOST

export const getScopeOfWork = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SCOPE_OF_WORK_REQUEST' });
            const { data } = await axios.get(`${API_URL}/scope-of-work`);
            dispatch({ type: 'SCOPE_OF_WORK_SUCCESS', scopeOfWork: data });
        }
        catch (error) {
            dispatch({ type: 'SCOPE_OF_WORK_FAILURE', error: error.message });
        }
    }
}

export const createScopeOfWork = (scopeOfWork) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SCOPE_OF_WORK_CREATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/scope-of-work`, scopeOfWork, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'SCOPE_OF_WORK_CREATE_SUCCESS', scopeOfWork: data });
            toast.success('Scope Of Work created successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'SCOPE_OF_WORK_CREATE_FAILURE', error: error.message });
        }
    }
}

export const updateScopeOfWork = (id, scopeOfWork) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SCOPE_OF_WORK_UPDATE_REQUEST' });
            const { data } = await axios.put(`${API_URL}/scope-of-work/${id}`, scopeOfWork, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'SCOPE_OF_WORK_UPDATE_SUCCESS', scopeOfWork: data });
            toast.success('Scope Of Work updated successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'SCOPE_OF_WORK_UPDATE_FAILURE', error: error.message });
        }
    }
}

export const deleteScopeOfWork = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SCOPE_OF_WORK_DELETE_REQUEST' });
            const config = {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            }
            const { data } = await axios.delete(`${API_URL}/scope-of-work/${id}`, config);
            dispatch({ type: 'SCOPE_OF_WORK_DELETE_SUCCESS', scopeOfWork: data });
            toast.success('Scope Of Work deleted successfully!');
        } catch (error) {
            dispatch({ type: 'SCOPE_OF_WORK_DELETE_FAILURE', error: error.message });
        }
    }
}

export const setRedirect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
} 