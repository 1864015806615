const initialState = {
    loading: false,
    installList: [],
    redirect: false,
};

const InstllList = (state = initialState, action) => {
    switch (action.type) {
        case 'INSTALL_LIST_REQUEST':
            return { ...state, loading: true };
        case 'INSTALL_LIST_SUCCESS':
            return { ...state, loading: false, installList: action.installList };
        case 'INSTALL_LIST_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'INSTALL_LIST_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'INSTALL_LIST_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, installList: state.installList.concat(action.installList) };
        case 'INSTALL_LIST_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'INSTALL_LIST_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'INSTALL_LIST_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: true, installList: state.installList.map(installList => installList._id === action.installList._id ? action.installList : installList) };
        case 'INSTALL_LIST_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'INSTALL_LIST_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'INSTALL_LIST_DELETE_SUCCESS':
            return { ...state, loading: false, installList: state.installList.filter(installList => installList._id !== action.installList) };
        case 'INSTALL_LIST_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default InstllList;