import { Suspense, useEffect, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from 'react-hot-toast';
import './App.css';
import ProtectedRoute from './components/ProtectedRoute';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SidebarLayout from './components/Sidebar/SidebarLayout';
import PulseLoader from "./components/PulseLoader";


const PageNotFound = lazy(() => import('./views/Others/PageNotFound'));
const Login = lazy(() => import('./views/Others/Login'));
const Dashboard = lazy(() => import('./views/Home/Dashboard'));
const EndUser = lazy(() => import("./views/Users/EndUser"));
const EndUserForm = lazy(() => import("./views/Users/EndUserForm"));
const AdminUser = lazy(() => import("./views/Users/AdminUser"));
const AdminUserForm = lazy(() => import("./views/Users/AdminUserForm"));
const CompanySetup = lazy(() => import("./views/CompanySetup/CompanySetup"));
const CompanySetupForm = lazy(() => import("./views/CompanySetup/CompanySetupForm"));
const HereAboutUs = lazy(() => import("./views/HereAboutUS/HereAboutUs"));
const HereAboutUsForm = lazy(() => import("./views/HereAboutUS/HereAboutUsForm"));
const TypeOfProperty = lazy(() => import("./views/TypeOfProperty/TypeOfProperty"));
const TypeOfPropertyForm = lazy(() => import("./views/TypeOfProperty/TypeOfPropertyForm"));
const ScopeOfWork = lazy(() => import("./views/ScopeOfWork/ScopeOfWork"));
const ScopeOfWorkForm = lazy(() => import("./views/ScopeOfWork/ScopeOfWorkForm"));
const PropertyUsage = lazy(() => import("./views/PropertyUsage/PropertyUsage"));
const PropertyUsageForm = lazy(() => import("./views/PropertyUsage/PropertyUsageForm"));
const SiteType = lazy(() => import("./views/SiteType/SiteType"));
const SiteTypeForm = lazy(() => import("./views/SiteType/SiteTypeForm"));
const Estimate = lazy(() => import("./views/Estimate/Estimate"));
const EstimateForm = lazy(() => import("./views/Estimate/EstimateForm"));
const GeneratePDF = lazy(() => import("./views/Estimate//PDF/GeneratePDF"));
const Photo = lazy(() => import("./views/Photo/Photo"));
const PhotoForm = lazy(() => import("./views/Photo/PhotoForm"));
const InstallList = lazy(() => import("./views/SiteType/List/InstallList"));
const InstallListForm = lazy(() => import("./views/SiteType/List/InstallListForm"));
const RemovalList = lazy(() => import("./views/SiteType/List/RemovalList"));
const RemovalListForm = lazy(() => import("./views/SiteType/List/RemovalListForm"));
const Subdivision = lazy(() => import("./views/Subdivision/Subdivision"));
const SubdivisionForm = lazy(() => import("./views/Subdivision/SubdivisionForm"));
const Prompt = lazy(() => import("./views/Prompts/Prompts"));
const PromptForm = lazy(() => import("./views/Prompts/PromptForm"));
const ForgotPassword = lazy(() => import("./views/Others/ForgotPassword"));
const ResetPassword = lazy(() => import("./views/Others/ResetPassword"));
const CoverPhoto = lazy(() => import("./views/Photo/CoverPhoto/CoverPhoto"));
const CoverPhotoForm = lazy(() => import("./views/Photo/CoverPhoto/CoverPhotoForm"));
const WhyUsPhoto = lazy(() => import("./views/Photo/WhyUSPhoto/WhyUsPhoto"));
const WhyUsPhotoForm = lazy(() => import("./views/Photo/WhyUSPhoto/WhyUsPhotoForm"));
const WhatToExpectPhoto = lazy(() => import("./views/Photo/WhatToExpectPhoto/WhatToExpectPhoto"));
const WhatToExpectPhotoForm = lazy(() => import("./views/Photo/WhatToExpectPhoto/WhatToExpectPhotoForm"));
const Timeline = lazy(() => import("./views/Timeline/Timeline"));
const TimelineForm = lazy(() => import("./views/Timeline/TimelineForm"));



const { userGetAll } = require('./actions/authActions');
const { getSiteSetting } = require('./actions/siteSettingActions');
const { getHereAboutUs } = require('./actions/hereAboutUsAction');
const { getTypeOfProperty } = require('./actions/typeOfPropertyActions');
const { getScopeOfWork } = require('./actions/scopeOfWorkActions');
const { getPropertyUsage } = require('./actions/propertyUsageActions');
const { getSiteType } = require('./actions/siteTypeActions');
const { fetchEstimate } = require('./actions/estimateActions');
const { getPhotos } = require('./actions/photoActions');
const { getInstallList } = require('./actions/installListActions');
const { getRemovalList } = require('./actions/removalListActions');
const { getSubdivision } = require('./actions/subdivisionActions');
const { getPrompt } = require('./actions/promptActions');
const { fetchPdfData } = require('./actions/pdfActions');
const { fetchCoverPhoto } = require('./actions/coverPhotoActions');
const { fetchWhyUsPhoto } = require('./actions/whyUsPhotoActions');
const { fetchWhatToExpect } = require('./actions/whatToExpectPhotoActions');
const { fetchTimeline } = require('./actions/timelineActions');

function App() {

  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(state => state.Auth);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(userGetAll());
      dispatch(getSiteSetting());
      dispatch(getHereAboutUs());
      dispatch(getTypeOfProperty());
      dispatch(getScopeOfWork());
      dispatch(getPropertyUsage());
      dispatch(getSiteType());
      dispatch(fetchEstimate());
      dispatch(getPhotos());
      dispatch(getInstallList());
      dispatch(getRemovalList());
      dispatch(getSubdivision());
      dispatch(getPrompt());
      dispatch(fetchPdfData());
      dispatch(fetchCoverPhoto());
      dispatch(fetchWhyUsPhoto());
      dispatch(fetchWhatToExpect());
      dispatch(fetchTimeline());
    }

  }, [isAuthenticated])// eslint-disable-line

  return (
    <>
      <Router>
        <Suspense fallback={<PulseLoader />}>
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
              <Routes>
                <Route element={<SidebarLayout />} >
                  <Route path="/" element={<ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>} />

                  <Route path="/admin-users" element={<ProtectedRoute>
                    <AdminUser type="Admin Users" />
                  </ProtectedRoute>} />

                  <Route path="/admin-users/add-admin-user" element={<ProtectedRoute>
                    <AdminUserForm FormName="Add Admin User" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/admin-users/edit-admin-user/:id" element={<ProtectedRoute>
                    <AdminUserForm FormName="Edit Admin User" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/end-users" element={<ProtectedRoute>
                    <EndUser type="Employee" />
                  </ProtectedRoute>} />

                  <Route path="/end-users/add-end-user" element={<ProtectedRoute>
                    <EndUserForm FormName="Add Employee" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/end-users/edit-end-user/:id" element={<ProtectedRoute>
                    <EndUserForm FormName="Edit Employee" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/company-setup" element={<ProtectedRoute>
                    <CompanySetup type="Company Setup" />
                  </ProtectedRoute>} />

                  <Route path="/company-setup/add-new-company-setup" element={<ProtectedRoute>
                    <CompanySetupForm FormName="Add Company Setup" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/company-setup/edit-company-setup/:id" element={<ProtectedRoute>
                    <CompanySetupForm FormName="Edit Company Setup" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/here-about-us" element={<ProtectedRoute>
                    <HereAboutUs type="Here About Us" />
                  </ProtectedRoute>} />

                  <Route path="/here-about-us/add-here-about-us" element={<ProtectedRoute>
                    <HereAboutUsForm FormName="Add Here About Us" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/here-about-us/edit-here-about-us/:id" element={<ProtectedRoute>
                    <HereAboutUsForm FormName="Edit Here About Us" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/type-of-property" element={<ProtectedRoute>
                    <TypeOfProperty type="Type Of Property" />
                  </ProtectedRoute>} />

                  <Route path="/type-of-property/add-type-of-property" element={<ProtectedRoute>
                    <TypeOfPropertyForm FormName="Add Type Of Property" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/type-of-property/edit-type-of-property/:id" element={<ProtectedRoute>
                    <TypeOfPropertyForm FormName="Edit Type Of Property" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/scope-of-work" element={<ProtectedRoute>
                    <ScopeOfWork type="Scope Of Work" />
                  </ProtectedRoute>} />

                  <Route path="/scope-of-work/add-scope-of-work" element={<ProtectedRoute>
                    <ScopeOfWorkForm FormName="Add Scope Of Work" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/scope-of-work/edit-scope-of-work/:id" element={<ProtectedRoute>
                    <ScopeOfWorkForm FormName="Edit Scope Of Work" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/property-usage" element={<ProtectedRoute>
                    <PropertyUsage type="Property Usage" />
                  </ProtectedRoute>} />

                  <Route path="/property-usage/add-property-usage" element={<ProtectedRoute>
                    <PropertyUsageForm FormName="Add Property Usage" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/property-usage/edit-property-usage/:id" element={<ProtectedRoute>
                    <PropertyUsageForm FormName="Edit Property Usage" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/room" element={<ProtectedRoute>
                    <SiteType type="Room Name/Cost" />
                  </ProtectedRoute>} />

                  <Route path="/site-type/add-site-type" element={<ProtectedRoute>
                    <SiteTypeForm FormName="Add Room" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/site-type/edit-site-type/:id" element={<ProtectedRoute>
                    <SiteTypeForm FormName="Edit Room" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/estimate" element={<ProtectedRoute>
                    <Estimate type="Estimate" />
                  </ProtectedRoute>} />

                  <Route path="/estimate/add-estimate" element={<ProtectedRoute>
                    <EstimateForm FormName="Add Estimate" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/estimate/edit-estimate/:id" element={<ProtectedRoute>
                    <EstimateForm FormName="Edit Estimate" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/estimate/generate-pdf/:id" element={<ProtectedRoute>
                    <GeneratePDF />
                  </ProtectedRoute>} />

                  <Route path="/photos" element={<ProtectedRoute>
                    <Photo type="Photos" />
                  </ProtectedRoute>} />

                  <Route path="/photos/add-photos" element={<ProtectedRoute>
                    <PhotoForm FormName="Add Photos" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/photos/edit-photos/:id" element={<ProtectedRoute>
                    <PhotoForm FormName="Edit Photos" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/install-list" element={<ProtectedRoute>
                    <InstallList type="Install List" />
                  </ProtectedRoute>} />

                  <Route path="/install-list/add-install-list" element={<ProtectedRoute>
                    <InstallListForm FormName="Add Install List" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/install-list/edit-install-list/:id" element={<ProtectedRoute>
                    <InstallListForm FormName="Edit Install List" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/removal-list" element={<ProtectedRoute>
                    <RemovalList type="Demo List" />
                  </ProtectedRoute>} />

                  <Route path="/removal-list/add-removal-list" element={<ProtectedRoute>
                    <RemovalListForm FormName="Add Demo List" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/removal-list/edit-removal-list/:id" element={<ProtectedRoute>
                    <RemovalListForm FormName="Edit Demo List" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/subdivision" element={<ProtectedRoute>
                    <Subdivision type="Subdivision" />
                  </ProtectedRoute>} />

                  <Route path="/subdivision/add-subdivision" element={<ProtectedRoute>
                    <SubdivisionForm FormName="Add Subdivision" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/subdivision/edit-subdivision/:id" element={<ProtectedRoute>
                    <SubdivisionForm FormName="Edit Subdivision" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/prompts" element={<ProtectedRoute>
                    <Prompt type="Prompt" />
                  </ProtectedRoute>} />

                  <Route path="/prompt/add-prompt" element={<ProtectedRoute>
                    <PromptForm FormName="Add Prompt" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/prompt/edit-prompt/:id" element={<ProtectedRoute>
                    <PromptForm FormName="Edit Prompt" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/cover-photo" element={<ProtectedRoute>
                    <CoverPhoto type="Cover Photo" />
                  </ProtectedRoute>} />

                  <Route path="/cover-photo/add-cover-photo" element={<ProtectedRoute>
                    <CoverPhotoForm FormName="Add Cover Photo" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/cover-photo/edit-cover-photo/:id" element={<ProtectedRoute>
                    <CoverPhotoForm FormName="Edit Cover Photo" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/why-us-photo" element={<ProtectedRoute>
                    <WhyUsPhoto type="Why Us Photo" />
                  </ProtectedRoute>} />

                  <Route path="/why-us-photo/add-why-us-photo" element={<ProtectedRoute>
                    <WhyUsPhotoForm FormName="Add Why Us Photo" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/why-us-photo/edit-why-us-photo/:id" element={<ProtectedRoute>
                    <WhyUsPhotoForm FormName="Edit Why Us Photo" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/what-to-expect-photo" element={<ProtectedRoute>
                    <WhatToExpectPhoto type="What To Expect Photo" />
                  </ProtectedRoute>} />

                  <Route path="/what-to-expect-photo/add-what-to-expect-photo" element={<ProtectedRoute>
                    <WhatToExpectPhotoForm FormName="Add What To Expect Photo" Editable="false" />
                  </ProtectedRoute>} />

                  <Route path="/what-to-expect-photo/edit-what-to-expect-photo/:id" element={<ProtectedRoute>
                    <WhatToExpectPhotoForm FormName="Edit What To Expect Photo" Editable="true" />
                  </ProtectedRoute>} />

                  <Route path="/timeline" element={<ProtectedRoute>
                    <Timeline type="Timeline" />
                  </ProtectedRoute>} />
                  {/* 
                  <Route path="/timeline/add-timeline" element={<ProtectedRoute>
                    <TimelineForm FormName="Add Timeline" Editable="false" />
                  </ProtectedRoute>} /> */}

                  <Route path="/timeline/edit-timeline/:id" element={<ProtectedRoute>
                    <TimelineForm FormName="Edit Timeline" Editable="true" />
                  </ProtectedRoute>} />

                </Route>

                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="*" element={<PageNotFound />} />

              </Routes>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
          </div>
        </Suspense>
        <Toaster
          position="top-center"
          reverseOrder={true}
        />
      </Router>
    </>
  );
}

export default App;

