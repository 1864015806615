const initialState = {
    loading: false,
    siteType: [],
    redirect: false,
};

const SiteType = (state = initialState, action) => {
    switch (action.type) {
        case 'SITE_TYPE_REQUEST':
            return { ...state, loading: true };
        case 'SITE_TYPE_SUCCESS':
            return { ...state, loading: false, siteType: action.siteType };
        case 'SITE_TYPE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SITE_TYPE_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'SITE_TYPE_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, siteType: state.siteType.concat(action.siteType) };
        case 'SITE_TYPE_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SITE_TYPE_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'SITE_TYPE_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: true, siteType: state.siteType.map(siteType => siteType._id === action.siteType._id ? action.siteType : siteType) };
        case 'SITE_TYPE_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SITE_TYPE_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'SITE_TYPE_DELETE_SUCCESS':
            return { ...state, loading: false, siteType: state.siteType.filter(siteType => siteType._id !== action.siteType) };
        case 'SITE_TYPE_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default SiteType;