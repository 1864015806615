const initialState = {
    loading: false,
    whatToExpect: [],
    redirect: false,
};

const WhatToExpect = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_WHATTOEXPECT_REQUEST':
            return { ...state, loading: true };
        case 'GET_WHATTOEXPECT_SUCCESS':
            return { ...state, loading: false, whatToExpect: action.whatToExpect };
        case 'GET_WHATTOEXPECT_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'WHATTOEXPECT_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'WHATTOEXPECT_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, whatToExpect: state.whatToExpect.concat(action.whatToExpect) };
        case 'WHATTOEXPECT_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'WHATTOEXPECT_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'WHATTOEXPECT_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: false, whatToExpect: state.whatToExpect.map(whatToExpect => whatToExpect._id === action.whatToExpect._id ? action.whatToExpect : whatToExpect) };
        case 'WHATTOEXPECT_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'WHATTOEXPECT_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'WHATTOEXPECT_DELETE_SUCCESS':
            return { ...state, loading: false, whatToExpect: state.whatToExpect.filter(whatToExpect => whatToExpect._id !== action.whatToExpect) };
        case 'WHATTOEXPECT_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default WhatToExpect;