import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { userLogout } from "../actions/authActions";
import { decryptData } from "./encryptionUtils";


const Session = () => {
    const dispatch = useDispatch();
    const getToken = localStorage.getItem("xat");
    const token = getToken ? decryptData(getToken) : null;
    const checkTokenExpiration = () => {
        if (token) {
            const base64Url = token.split(".")[1];
            const decodedData = JSON.parse(window.atob(base64Url));
            const verifyExp = decodedData.exp * 1000;
            const tokenVerify = verifyExp < Date.now();
            if (tokenVerify) {
                dispatch(userLogout());
                window.localStorage.clear();
                toast.error("Session expired, please login again to continue!");
            }
        } else {
            dispatch(userLogout());
            window.localStorage.clear();
        }
    };

    useEffect(() => {
        checkTokenExpiration();
        // eslint-disable-next-line
    }, [token]);

    return <></>;
};

export default Session;