import axios from "axios";
import { toast } from 'react-hot-toast';

const API_URL = process.env.REACT_APP_API_HOST;


export const fetchWhyUsPhoto = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_WHYUSPHOTO_REQUEST' });
            const response = await axios.get(`${API_URL}/why-us-photo`, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'GET_WHYUSPHOTO_SUCCESS', whyUsPhoto: response.data });
        } catch (error) {
            dispatch({ type: 'GET_WHYUSPHOTO_FAILURE', error: error.message });
        }
    }
};

export const createWhyUsPhoto = (whyUsPhoto) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'WHYUSPHOTO_CREATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/why-us-photo`, whyUsPhoto, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'WHYUSPHOTO_CREATE_SUCCESS', whyUsPhoto: data });
            toast.success('WhyUsPhoto Created successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'WHYUSPHOTO_CREATE_FAILURE', error: error.message });
        }
    }
}

export const updateWhyUsPhoto = (id, whyUsPhoto) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'WHYUSPHOTO_UPDATE_REQUEST' });
            const { data } = await axios.put(`${API_URL}/why-us-photo/${id}`, whyUsPhoto, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'WHYUSPHOTO_UPDATE_SUCCESS', whyUsPhoto: data });
            toast.success('WhyUsPhoto Updated successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'WHYUSPHOTO_UPDATE_FAILURE', error: error.message });
        }
    }
}


export const deleteWhyUsPhoto = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'WHYUSPHOTO_DELETE_REQUEST' });
            const config = {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            }
            const { data } = await axios.delete(`${API_URL}/why-us-photo/${id}`, config);
            dispatch({ type: 'WHYUSPHOTO_DELETE_SUCCESS', whyUsPhoto: data._id });
            toast.success('WhyUsPhoto Deleted successfully!');
        } catch (error) {
            dispatch({ type: 'WHYUSPHOTO_DELETE_FAILURE', error: error.message });
        }
    }
}

export const setRedirect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
}