const initialState = {
    loading: false,
    subdivision: [],
    redirect: false,
};

const Subdivision = (state = initialState, action) => {
    switch (action.type) {
        case 'SUBDIVISION_REQUEST':
            return { ...state, loading: true };
        case 'SUBDIVISION_SUCCESS':
            return { ...state, loading: false, subdivision: action.subdivision };
        case 'SUBDIVISION_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SUBDIVISION_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'SUBDIVISION_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, subdivision: state.subdivision.concat(action.subdivision) };
        case 'SUBDIVISION_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SUBDIVISION_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'SUBDIVISION_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: true, subdivision: state.subdivision.map(subdivision => subdivision._id === action.subdivision._id ? action.subdivision : subdivision) };
        case 'SUBDIVISION_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SUBDIVISION_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'SUBDIVISION_DELETE_SUCCESS':
            return { ...state, loading: false, subdivision: state.subdivision.filter(subdivision => subdivision._id !== action.subdivision) };
        case 'SUBDIVISION_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default Subdivision;