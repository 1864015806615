const initialState = {
    loading: false,
    removalList: [],
    redirect: false,
};

const RemovalList = (state = initialState, action) => {
    switch (action.type) {
        case 'REMOVAL_LIST_REQUEST':
            return { ...state, loading: true };
        case 'REMOVAL_LIST_SUCCESS':
            return { ...state, loading: false, removalList: action.removalList };
        case 'REMOVAL_LIST_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'REMOVAL_LIST_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'REMOVAL_LIST_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, removalList: state.removalList.concat(action.removalList) };
        case 'REMOVAL_LIST_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'REMOVAL_LIST_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'REMOVAL_LIST_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: true, removalList: state.removalList.map(removalList => removalList._id === action.removalList._id ? action.removalList : removalList) };
        case 'REMOVAL_LIST_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'REMOVAL_LIST_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'REMOVAL_LIST_DELETE_SUCCESS':
            return { ...state, loading: false, removalList: state.removalList.filter(removalList => removalList._id !== action.removalList) };
        case 'REMOVAL_LIST_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default RemovalList;