const initialState = {
    loading: false,
    pdfData: [],
    staticData: {
        title: `<h2 style="color: "#000000">Project Investment & Concept Design</h2>`,
        whyUsOneHeading: "ZERO Change Orders Guarantee",
        whyUsOneContent: "We take design and preconstruction very, very seriously. By taking the time to do a PROPER Feasability study we eliminate any potential for those pesky change orders and 'contractor games'.",
        whyUsTwoHeading: "We Don't Markup Materials",
        whyUsTwoContent: "Some might call us crazy, But we simply don't see any value in marking up materials, we think the hidden value in these complicated project is in the management of the project, not overcharging for things you could go out & buy at the store. Our price is your price.",
        whyUsThreeHeading: "Shop Till You Drop",
        whyUsThreeContent: "Unlike other companies who practice Design-Build, we allow you to shop our design once it is prepared. We don't believe in trapping people into a build contract during the design process. We will beat any credible bid by 500$ or we will buy you dinner!",
        whyUsFourHeading: "We Design With Value In Mind",
        whyUsFourContent: "Unlike an architect or a typical interior designer, we actually build the things we draw. This gives you, as a consumer, a huge advantage when it comes to value engineering the plans so that your project can come into fruition. We also don't make our living on the design, so we are incentivized to draw you something that can actually be built in your budget. This eliminates the common disappointment we see after the project is drawn and you realize your project is going to cost far more then you anticipated.",
        whyUsDisclaimer: "*Subject to client-initiated scope changes and interior wall inspection*",
        nextStepOneContent: "Design Contract - When you are ready, we send over the design contract for your signature. This, together, commits us to the design process: including, architectual design, kitchen and bath design, material selections, engineering, and 3D renderings as needed.",
        nextStepTwoContent: "Measure Meeting - Our design team will come to your home and measure the area intended for renovation. Here you and your designer will go over your renovation goals in great detail.",
        nextStepThreeContent: "Feasibility Study - Your project is thoroughly reviewed by our licensed General Contractor, Licensed Plumber, Licensed Electrician and Licensed Mechanical specialist to confirm what is possible within your home.",
        nextStepFourContent: "Proposed remodel plan + selections - The design team will present your plan for your renovation. Together, you will adjust the plan as necessary until it meets your goals. Material selections will also be chosen, and a budget finalized.",
        timelineHeadingOne: "Sign Initial Contract",
        timelineHeadingTwo: "Start of Architectural, Design & Feasibility Phase.[Date is fluid based on date of contract signing]",
        timelineHeadingThree: "Architectural Design Phase 3 - 5 weeks",
        timelineHeadingFour: "Pre-Construction Phase 2 - 4 weeks",
        timelineHeadingFive: "Construction Phase 8 - 12 weeks",
        timelineContentOne: "Once the contract is signed, clients are invoiced for the initial phase cost which includes architectural, design, and pre-construction review",
        timelineContentTwo: "Initial Phase Our team comes to your house to review and measure the space to be renovated. Review of the overall project usage and feasibility",
        timelineContentThree: "This is where you and our team collaborate to create your remodel plan. If ARB approval is needed this is when this happens.",
        timelineContentFour: "This is where materials are specified, permit documents are prepared, our estimating and build teams review the feasibility of the design plan based your home's unique features and create your build budget.",
        timelineContentFive: "Construction Phase Our build team and subcontractors work to execute the agreed plan in your home.",
        ownership: "We take Ownership of all aspects of each project. Practicing an “anything it takes” attitude and “never quit” mentality; our drive, decision making, and sense of urgency is what sets us apart from the competition.",
        ethics: "At the foundation of our business are the principles of honesty, ethical behavior, and fairness.",
        communication: "We strive for proactive and open communication with our clients and our team this openness allows us to give our clients peace of mind and ensures our high standard for customer service and quality are met.",
        collaboration: "Our goal is to make your dreams come alive. The strength of our relationship between clients and our team is at the core of great results.",
        innovation: "We constantly Innovate. Driving better process, providing innovative technology and a passion to question everything. We believe that consistently providing proactive, outside the box solutions help us perform better and serve our clients.",
        attentionToDetail: "Our process works in layers so multiple team member review and revise each project bringing a different set of eyes and perspective to our work.",
    },
    redirect: false,
};

const PdfData = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PDFDATA_REQUEST':
            return { ...state, loading: true };
        case 'GET_PDFDATA_SUCCESS':
            return { ...state, loading: false, pdfData: action.pdfData };
        case 'GET_PDFDATA_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'PDFDATA_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'PDFDATA_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, pdfData: state.pdfData.concat(action.pdfData) };
        case 'PDFDATA_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'PDFDATA_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'PDFDATA_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: true, pdfData: state.pdfData.map(pdfData => pdfData._id === action.pdfData._id ? action.pdfData : pdfData) };
        case 'PDFDATA_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        default:
            return state;
    }
};

export default PdfData;