import axios from 'axios';
import { toast } from 'react-hot-toast';


const API_URL = process.env.REACT_APP_API_HOST

export const getPropertyUsage = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'PROPERTY_USAGE_REQUEST' });
            const { data } = await axios.get(`${API_URL}/property-usage`);
            dispatch({ type: 'PROPERTY_USAGE_SUCCESS', propertyUsage: data });
        }
        catch (error) {
            dispatch({ type: 'PROPERTY_USAGE_FAILURE', error: error.message });
        }
    }
}

export const createPropertyUsage = (propertyUsage) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'PROPERTY_USAGE_CREATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/property-usage`, propertyUsage, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'PROPERTY_USAGE_CREATE_SUCCESS', propertyUsage: data });
            toast.success('Property Usage created successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'PROPERTY_USAGE_CREATE_FAILURE', error: error.message });
        }
    }
}

export const updatePropertyUsage = (id, propertyUsage) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'PROPERTY_USAGE_UPDATE_REQUEST' });
            const { data } = await axios.put(`${API_URL}/property-usage/${id}`, propertyUsage, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'PROPERTY_USAGE_UPDATE_SUCCESS', propertyUsage: data });
            toast.success('Property Usage updated successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'PROPERTY_USAGE_UPDATE_FAILURE', error: error.message });
        }
    }
}

export const deletePropertyUsage = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'PROPERTY_USAGE_DELETE_REQUEST' });
            const config = {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            }
            const { data } = await axios.delete(`${API_URL}/property-usage/${id}`, config);
            dispatch({ type: 'PROPERTY_USAGE_DELETE_SUCCESS', propertyUsage: data });
            toast.success('Property Usage deleted successfully!');
        } catch (error) {
            dispatch({ type: 'PROPERTY_USAGE_DELETE_FAILURE', error: error.message });
        }
    }
}

export const setRedirect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
} 