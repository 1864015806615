import { encryptData, decryptData } from './encryptionUtils';

const STORAGE_KEY = 'ADMIN_PORTAL';

export const saveState = (state) => {
    try {
        const encryptedState = encryptData(state);
        localStorage.setItem(STORAGE_KEY, encryptedState);
    } catch (error) {
        console.error('Error saving state:', error);
    }
};

export const loadState = () => {
    try {
        const encryptedState = localStorage.getItem(STORAGE_KEY);
        if (!encryptedState) {
            return undefined;
        }

        return decryptData(encryptedState);
    } catch (error) {
        console.error('Error loading state:', error);
        return undefined;
    }
};




// const loadState = () => {
//     try {
//         const serializedState = localStorage.getItem("remax-admin");
//         if (serializedState === null) {
//             return undefined;
//         }
//         return JSON.parse(serializedState);
//     } catch (err) {
//         return undefined;
//     }
// };

// const saveState = (state) => {
//     try {
//         const serializedState = JSON.stringify(state);
//         localStorage.setItem("remax-admin", serializedState);
//     } catch (err) {
//         throw new Error(err);
//     }
// };

// export { loadState, saveState };