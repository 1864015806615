import axios from 'axios';
import { toast } from 'react-hot-toast';


const API_URL = process.env.REACT_APP_API_HOST

export const getSiteSetting = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SITE_SETTING_REQUEST' });
            const { data } = await axios.get(`${API_URL}/site-setting`, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'SITE_SETTING_SUCCESS', siteSetting: data });
        }
        catch (error) {
            dispatch({ type: 'SITE_SETTING_FAILURE', error: error.message });
        }
    }
}

export const createSiteSetting = (siteSetting) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SITE_SETTING_CREATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/site-setting`, siteSetting, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'SITE_SETTING_CREATE_SUCCESS', siteSetting: data });
            toast.success('Site Setting added successfully!');
        } catch (error) {
            toast.error(error.message);
            dispatch({ type: 'SITE_SETTING_CREATE_FAILURE', error: error.message });
        }
    }
}

export const updateSiteSetting = (id, siteSetting) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SITE_SETTING_UPDATE_REQUEST' });
            const { data } = await axios.put(`${API_URL}/site-setting/${id}`, siteSetting, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'SITE_SETTING_UPDATE_SUCCESS', siteSetting: data });
            toast.success('Site Setting Updated successfully!');
        } catch (error) {
            toast.error(error.message);
            dispatch({ type: 'SITE_SETTING_UPDATE_FAILURE', error: error.message });
        }
    }
}

export const deleteSiteSetting = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SITE_SETTING_DELETE_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('xat')
                }
            }
            const { data } = await axios.delete(`${API_URL}/site-setting/${id}`, config);
            dispatch({ type: 'SITE_SETTING_DELETE_SUCCESS', siteSetting: data });
            toast.success('Site Setting Deleted successfully!');
        } catch (error) {
            dispatch({ type: 'SITE_SETTING_DELETE_FAILURE', error: error.message });
        }
    }
}

export const setRedirect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
}