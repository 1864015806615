const initialState = {
    loading: false,
    scopeOfWork: [],
    redirect: false,
};

const ScopeOfWork = (state = initialState, action) => {
    switch (action.type) {
        case 'SCOPE_OF_WORK_REQUEST':
            return { ...state, loading: true };
        case 'SCOPE_OF_WORK_SUCCESS':
            return { ...state, loading: false, scopeOfWork: action.scopeOfWork };
        case 'SCOPE_OF_WORK_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SCOPE_OF_WORK_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'SCOPE_OF_WORK_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, scopeOfWork: state.scopeOfWork.concat(action.scopeOfWork) };
        case 'SCOPE_OF_WORK_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SCOPE_OF_WORK_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'SCOPE_OF_WORK_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: true, scopeOfWork: state.scopeOfWork.map(scopeOfWork => scopeOfWork._id === action.scopeOfWork._id ? action.scopeOfWork : scopeOfWork) };
        case 'SCOPE_OF_WORK_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SCOPE_OF_WORK_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'SCOPE_OF_WORK_DELETE_SUCCESS':
            return { ...state, loading: false, scopeOfWork: state.scopeOfWork.filter(scopeOfWork => scopeOfWork._id !== action.scopeOfWork) };
        case 'SCOPE_OF_WORK_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default ScopeOfWork;