import { React, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../Navbar';
import './Sidebar.css'
import { useDispatch } from 'react-redux';
import Session from '../../utils/Session';
import { useSelector } from 'react-redux';

const { userLogout } = require('../../actions/authActions');

const Sidebar = () => {
    const dispatch = useDispatch();
    const arrow = useRef([]);
    const [subMenuIndex, setSubMenuIndex] = useState(-1);
    const { siteSetting } = useSelector(state => state.SiteSetting);



    const handleMenuClick = (index) => {
        if (subMenuIndex === index) {
            setSubMenuIndex(-1);
        } else {
            setSubMenuIndex(index);
        }
    };
    const currentUrl = window.location.pathname;


    return (
        <>
            <Session />
            <Navbar />
            <div className="nav" id="navbar">
                <nav className="nav__container">
                    <div>
                        <Link to="/" className="nav__link nav__logo">
                            <i className='bx bxs-disc nfav__icon' ></i>
                            <span className="nav__logo-name">{siteSetting[0]?.name}</span>
                        </Link>

                        <div className="nav__list">
                            <div className="nav__items">
                                <h3 className="nav__subtitle">Profile</h3>

                                {/* <Link to="/" className={
                                    currentUrl === '/' ? 'nav__link active' : 'nav__link'
                                }>
                                    <i className='bx bx-home nav__icon' ></i>
                                    <span className="nav__name">Dashboard</span>
                                </Link> */}

                                <Link to="/company-setup" className={
                                    currentUrl === '/company-setup' ? 'nav__link active' : 'nav__link'
                                }>
                                    <i className='bx bx-cog nav__icon' ></i>
                                    <span className="nav__name">Company Setup</span>
                                </Link>

                                <div className={
                                    subMenuIndex === 2 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[2] = el)}
                                    onClick={() => handleMenuClick(2)}>
                                    <div className="nav__link">
                                        <i className='bx bx-user nav__icon' ></i>
                                        <span className="nav__name">Team Members</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/end-users" className="nav__dropdown-item">Employees</Link>
                                            <Link to="/admin-users" className="nav__dropdown-item">Admins</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className={
                                    subMenuIndex === 3 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[3] = el)}
                                    onClick={() => handleMenuClick(3)}>
                                    <div className="nav__link">
                                        <i className='bx bx-images nav__icon' ></i>
                                        <span className="nav__name">Photos</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/photos" className="nav__dropdown-item">Room Photos</Link>
                                            <Link to="/cover-photo" className="nav__dropdown-item">Cover Photos</Link>
                                            <Link to="/why-us-photo" className="nav__dropdown-item">Why Us Photos</Link>
                                            <Link to="/what-to-expect-photo" className="nav__dropdown-item">What To Expect Photo</Link>
                                        </div>
                                    </div>
                                </div>

                                {/* <Link to="/photos" className={
                                    currentUrl === '/photos' ? 'nav__link active' : 'nav__link'
                                }>
                                    <i className='bx bx-images nav__icon' ></i>
                                    <span className="nav__name">Photos</span>
                                </Link> */}

                                {/* <Link to="/room" className={
                                    currentUrl === '/room' ? 'nav__link active' : 'nav__link'
                                }>
                                    <i className='bx bx-money nav__icon' ></i>
                                    <span className="nav__name">Room Name/Cost</span>
                                </Link> */}

                                <div className={
                                    subMenuIndex === 4 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[4] = el)}
                                    onClick={() => handleMenuClick(4)}>
                                    <div className="nav__link">
                                        <i className='bx bx-money nav__icon' ></i>
                                        <span className="nav__name">Room</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/room" className="nav__dropdown-item">Room Name/Cost</Link>
                                            <Link to="/removal-list" className="nav__dropdown-item">Demo List</Link>
                                            <Link to="/install-list" className="nav__dropdown-item">Install List</Link>
                                        </div>
                                    </div>
                                </div>

                                <Link to="/prompts" className={
                                    currentUrl === '/prompts' ? 'nav__link active' : 'nav__link'
                                }>
                                    <i className='bx bxl-android nav__icon' ></i>
                                    <span className="nav__name">Prompts</span>
                                </Link>

                                <Link to="/estimate" className={
                                    currentUrl === '/estimate' ? 'nav__link active' : 'nav__link'
                                }>
                                    <i className='bx bx-building-house nav__icon' ></i>
                                    <span className="nav__name">Estimate Form</span>
                                </Link>

                                <Link to="/timeline" className={
                                    currentUrl === '/timeline' ? 'nav__link active' : 'nav__link'
                                }>
                                    <i className='bx bx-timer nav__icon' ></i>
                                    <span className="nav__name">Timeline</span>
                                </Link>

                                <div className={
                                    subMenuIndex === 5 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[5] = el)}
                                    onClick={() => handleMenuClick(5)}>
                                    <div className="nav__link">
                                        <i className='bx bx-archive-in nav__icon' ></i>
                                        <span className="nav__name">Dropdowns</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/here-about-us" className="nav__dropdown-item">Here About Us</Link>
                                            <Link to="/type-of-property" className="nav__dropdown-item">Type of Property</Link>
                                            <Link to="/property-usage" className="nav__dropdown-item">Property Usage</Link>
                                            <Link to="/subdivision" className="nav__dropdown-item">Subdivision</Link>
                                            {/* <Link to="/scope-of-work" className="nav__dropdown-item">Scope of Work</Link> */}
                                        </div>
                                    </div>
                                </div>


                                {/* <div className={
                                    subMenuIndex === 4 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[4] = el)}
                                    onClick={() => handleMenuClick(4)}>
                                    <div className="nav__link">
                                        <i className='bx bx-cog nav__icon' ></i>
                                        <span className="nav__name">Settings</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/site-setting" className="nav__dropdown-item">Site Setting</Link>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className={
                                    subMenuIndex === 2 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[2] = el)}
                                    onClick={() => handleMenuClick(2)}>
                                    <div className="nav__link">
                                        <i className='bx bx-user nav__icon' ></i>
                                        <span className="nav__name">Customer</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/customers" className="nav__dropdown-item">All Customers</Link>
                                            <Link to="/customer/pending-requests" className="nav__dropdown-item">Pending Customers</Link>
                                            <Link to="/customer/rejected-requests" className="nav__dropdown-item">Rejected Customers</Link>
                                            <Link to="/customer/blocked-requests" className="nav__dropdown-item">Blocked Customers</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className={
                                    subMenuIndex === 1 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[1] = el)}
                                    onClick={() => handleMenuClick(1)}>
                                    <div className="nav__link">
                                        <i className='bx bx-category nav__icon' ></i>
                                        <span className="nav__name">Category</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/categories" className="nav__dropdown-item">Categories</Link>
                                            <Link to="/sub-categories" className="nav__dropdown-item">Sub Categories</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className={
                                    subMenuIndex === 3 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[3] = el)}
                                    onClick={() => handleMenuClick(3)}>
                                    <div className="nav__link">
                                        <i className='bx bx-store-alt nav__icon' ></i>
                                        <span className="nav__name">Products</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/products" className="nav__dropdown-item">All Products</Link>
                                            <Link to="/products/draft" className="nav__dropdown-item">Drafts</Link>
                                            <Link to="/products/publish" className="nav__dropdown-item">Publish</Link>
                                            <Link to="/products/unpublish" className="nav__dropdown-item">Unpublish</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className={
                                    subMenuIndex === 5 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[5] = el)}
                                    onClick={() => handleMenuClick(5)}>
                                    <div className="nav__link">
                                        <i className='bx bx-file nav__icon' ></i>
                                        <span className="nav__name">Reports</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/sale-report" className="nav__dropdown-item">Sale Report</Link>
                                        </div>
                                    </div>
                                </div>
                                <Link to="/tier" className={
                                    currentUrl === '/tier' ? 'nav__link active' : 'nav__link'
                                } >
                                    <i className='bx bx-trending-up nav__icon' ></i>
                                    <span className="nav__name">Discount Tier</span>
                                </Link>

                                <Link to="/tag" className={
                                    currentUrl === '/tag' ? 'nav__link active' : 'nav__link'
                                } >
                                    <i className='bx bx-tag nav__icon' ></i>
                                    <span className="nav__name">Tag</span>
                                </Link>

                                <Link to="/brands" className={
                                    currentUrl === '/brands' ? 'nav__link active' : 'nav__link'
                                } >
                                    <i className='bx bx-bold nav__icon'></i>
                                    <span className="nav__name">Brands </span>
                                </Link>
                                <Link to="/models" className={
                                    currentUrl === '/models' ? 'nav__link active' : 'nav__link'
                                } >
                                    <i className='bx bxs-devices nav__icon'></i>
                                    <span className="nav__name">Models </span>
                                </Link>

                                <Link to="/designs" className={
                                    currentUrl === '/designs' ? 'nav__link active' : 'nav__link'
                                } >
                                    <i className='bx bxl-sketch nav__icon'></i>
                                    <span className="nav__name">Designs </span>
                                </Link>

                                <Link to="/carriers" className={
                                    currentUrl === '/carriers' ? 'nav__link active' : 'nav__link'
                                } >
                                    <i className='bx bx-mobile-alt nav__icon'></i>
                                    <span className="nav__name">Carriers </span>
                                </Link>


                                <Link to="/orders" className={
                                    currentUrl === '/orders' ? 'nav__link active' : 'nav__link'
                                } >
                                    <i className='bx bxs-purchase-tag-alt nav__icon' ></i>
                                    <span className="nav__name">Orders </span>
                                </Link>

                                <div className={
                                    subMenuIndex === 4 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[4] = el)}
                                    onClick={() => handleMenuClick(4)}>
                                    <div className="nav__link">
                                        <i className='bx bx-cog nav__icon' ></i>
                                        <span className="nav__name">Settings</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/banners" className="nav__dropdown-item">Banners</Link>
                                            <Link to="/ads-spots" className="nav__dropdown-item">Ads Spots</Link>
                                            <Link to="/tax" className="nav__dropdown-item">State Tax</Link>
                                            <Link to="/dropshipfee" className="nav__dropdown-item">Dropship Fee</Link>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="nav__link nav__logout" onClick={() => dispatch(userLogout())} >
                        <i className='bx bx-log-out nav__icon' ></i>
                        <span className="nav__name">Log Out</span>
                    </div>
                </nav >
            </div >
        </>
    )
}

export default Sidebar