import axios from "axios";
import { toast } from 'react-hot-toast';

const API_URL = process.env.REACT_APP_API_HOST;


export const fetchWhatToExpect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_WHATTOEXPECT_REQUEST' });
            const response = await axios.get(`${API_URL}/what-to-expect-photo`, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'GET_WHATTOEXPECT_SUCCESS', whatToExpect: response.data });
        } catch (error) {
            dispatch({ type: 'GET_WHATTOEXPECT_FAILURE', error: error.message });
        }
    }
};

export const createWhatToExpect = (whatToExpect) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'WHATTOEXPECT_CREATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/what-to-expect-photo`, whatToExpect, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'WHATTOEXPECT_CREATE_SUCCESS', whatToExpect: data });
            toast.success('WhatToExpect Created successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'WHATTOEXPECT_CREATE_FAILURE', error: error.message });
        }
    }
}

export const updateWhatToExpect = (id, whatToExpect) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'WHATTOEXPECT_UPDATE_REQUEST' });
            const { data } = await axios.put(`${API_URL}/what-to-expect-photo/${id}`, whatToExpect, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'WHATTOEXPECT_UPDATE_SUCCESS', whatToExpect: data });
            toast.success('WhatToExpect Updated successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'WHATTOEXPECT_UPDATE_FAILURE', error: error.message });
        }
    }
}


export const deleteWhatToExpect = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'WHATTOEXPECT_DELETE_REQUEST' });
            const config = {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            }
            const { data } = await axios.delete(`${API_URL}/what-to-expect-photo/${id}`, config);
            dispatch({ type: 'WHATTOEXPECT_DELETE_SUCCESS', whatToExpect: data._id });
            toast.success('WhatToExpect Deleted successfully!');
        } catch (error) {
            dispatch({ type: 'WHATTOEXPECT_DELETE_FAILURE', error: error.message });
        }
    }
}

export const setRedirect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
}