import axios from "axios";
import { toast } from 'react-hot-toast';

const API_URL = process.env.REACT_APP_API_HOST;


export const fetchTimeline = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_TIMELINE_REQUEST' });
            const response = await axios.get(`${API_URL}/timeline`, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'GET_TIMELINE_SUCCESS', timeline: response.data });
        } catch (error) {
            dispatch({ type: 'GET_TIMELINE_FAILURE', error: error.message });
        }
    }
};

export const createTimeline = (timeline) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'TIMELINE_CREATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/timeline`, timeline, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'TIMELINE_CREATE_SUCCESS', timeline: data });
            toast.success('Timeline Created successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'TIMELINE_CREATE_FAILURE', error: error.message });
        }
    }
}

export const updateTimeline = (id, timeline) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'TIMELINE_UPDATE_REQUEST' });
            const { data } = await axios.put(`${API_URL}/timeline/${id}`, timeline, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'TIMELINE_UPDATE_SUCCESS', timeline: data });
            toast.success('Timeline Updated successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'TIMELINE_UPDATE_FAILURE', error: error.message });
        }
    }
}


export const deleteTimeline = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'TIMELINE_DELETE_REQUEST' });
            const config = {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            }
            const { data } = await axios.delete(`${API_URL}/timeline/${id}`, config);
            dispatch({ type: 'TIMELINE_DELETE_SUCCESS', timeline: data._id });
            toast.success('Timeline Deleted successfully!');
        } catch (error) {
            dispatch({ type: 'TIMELINE_DELETE_FAILURE', error: error.message });
        }
    }
}

export const setRedirect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
}