const initialState = {
    loading: false,
    typeOfProperty: [],
    redirect: false,
};

const TypeOfProperty = (state = initialState, action) => {
    switch (action.type) {
        case 'TYPE_OF_PROPERTY_REQUEST':
            return { ...state, loading: true };
        case 'TYPE_OF_PROPERTY_SUCCESS':
            return { ...state, loading: false, typeOfProperty: action.typeOfProperty };
        case 'TYPE_OF_PROPERTY_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'TYPE_OF_PROPERTY_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'TYPE_OF_PROPERTY_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, typeOfProperty: state.typeOfProperty.concat(action.typeOfProperty) };
        case 'TYPE_OF_PROPERTY_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'TYPE_OF_PROPERTY_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'TYPE_OF_PROPERTY_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: true, typeOfProperty: state.typeOfProperty.map(typeOfProperty => typeOfProperty._id === action.typeOfProperty._id ? action.typeOfProperty : typeOfProperty) };
        case 'TYPE_OF_PROPERTY_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'TYPE_OF_PROPERTY_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'TYPE_OF_PROPERTY_DELETE_SUCCESS':
            return { ...state, loading: false, typeOfProperty: state.typeOfProperty.filter(typeOfProperty => typeOfProperty._id !== action.typeOfProperty) };
        case 'TYPE_OF_PROPERTY_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default TypeOfProperty;