const initialState = {
    loading: false,
    estimate: [],
    redirect: false,
};

const Estimate = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ESTIMATE_REQUEST':
            return { ...state, loading: true };
        case 'GET_ESTIMATE_SUCCESS':
            return { ...state, loading: false, estimate: action.estimate };
        case 'GET_ESTIMATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'ESTIMATE_CREATE_REQUEST':
            return { ...state, loading: true };
        case 'ESTIMATE_CREATE_SUCCESS':
            return { ...state, loading: false, redirect: true, estimate: state.estimate.concat(action.estimate) };
        case 'ESTIMATE_CREATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'ESTIMATE_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'ESTIMATE_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: false, estimate: state.estimate.map(estimate => estimate._id === action.estimate._id ? action.estimate : estimate) };
        case 'ESTIMATE_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'ESTIMATE_MODAL_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'ESTIMATE_MODAL_UPDATE_SUCCESS':
            return { ...state, loading: false, redirect: false, estimate: state.estimate.map(estimate => estimate._id === action.estimate._id ? action.estimate : estimate) };
        case 'ESTIMATE_MODAL_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'ESTIMATE_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'ESTIMATE_DELETE_SUCCESS':
            return { ...state, loading: false, estimate: state.estimate.filter(estimate => estimate._id !== action.estimate) };
        case 'ESTIMATE_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SET_REDIRECT':
            return { ...state, redirect: false };
        default:
            return state;
    }
};

export default Estimate;