import axios from 'axios';
import { toast } from 'react-hot-toast';


const API_URL = process.env.REACT_APP_API_HOST

export const getInstallList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'INSTALL_LIST_REQUEST' });
            const { data } = await axios.get(`${API_URL}/install-list`, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'INSTALL_LIST_SUCCESS', installList: data });
        }
        catch (error) {
            dispatch({ type: 'INSTALL_LIST_FAILURE', error: error.message });
        }
    }
}

export const createInstallList = (installList) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'INSTALL_LIST_CREATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/install-list`, installList, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'INSTALL_LIST_CREATE_SUCCESS', installList: data });
            toast.success('Install List added successfully!');
        } catch (error) {
            toast.error(error.message);
            dispatch({ type: 'INSTALL_LIST_CREATE_FAILURE', error: error.message });
        }
    }
}

export const updateInstallList = (id, installList) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'INSTALL_LIST_UPDATE_REQUEST' });
            const { data } = await axios.put(`${API_URL}/install-list/${id}`, installList, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'INSTALL_LIST_UPDATE_SUCCESS', installList: data });
            toast.success('Install List Updated successfully!');
        } catch (error) {
            toast.error(error.message);
            dispatch({ type: 'INSTALL_LIST_UPDATE_FAILURE', error: error.message });
        }
    }
}

export const deleteInstallList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'INSTALL_LIST_DELETE_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('xat')
                }
            }
            const { data } = await axios.delete(`${API_URL}/install-list/${id}`, config);
            dispatch({ type: 'INSTALL_LIST_DELETE_SUCCESS', installList: data });
            toast.success('Install List Deleted successfully!');
        } catch (error) {
            dispatch({ type: 'INSTALL_LIST_DELETE_FAILURE', error: error.message });
        }
    }
}

export const setRedirect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
}

export const setRedirectInstall = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
}