import axios from 'axios';
import { toast } from 'react-hot-toast';


const API_URL = process.env.REACT_APP_API_HOST

export const getPrompt = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'PROMPT_REQUEST' });
            const { data } = await axios.get(`${API_URL}/prompt`, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'PROMPT_SUCCESS', prompt: data });
        }
        catch (error) {
            dispatch({ type: 'PROMPT_FAILURE', error: error.message });
        }
    }
}

export const createPrompt = (prompt) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'PROMPT_CREATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/prompt`, prompt, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'PROMPT_CREATE_SUCCESS', prompt: data });
            toast.success('Prompt added successfully!');
        } catch (error) {
            toast.error(error.message);
            dispatch({ type: 'PROMPT_CREATE_FAILURE', error: error.message });
        }
    }
}

export const updatePrompt = (id, prompt) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'PROMPT_UPDATE_REQUEST' });
            const { data } = await axios.put(`${API_URL}/prompt/${id}`, prompt, {
                headers: {
                    Authorization: localStorage.getItem('xat')
                }
            });
            dispatch({ type: 'PROMPT_UPDATE_SUCCESS', prompt: data });
            toast.success('Prompt Updated successfully!');
        } catch (error) {
            toast.error(error.message);
            dispatch({ type: 'PROMPT_UPDATE_FAILURE', error: error.message });
        }
    }
}

export const deletePrompt = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'PROMPT_DELETE_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('xat')
                }
            }
            const { data } = await axios.delete(`${API_URL}/prompt/${id}`, config);
            dispatch({ type: 'PROMPT_DELETE_SUCCESS', prompt: data });
            toast.success('Prompt Deleted successfully!');
        } catch (error) {
            dispatch({ type: 'PROMPT_DELETE_FAILURE', error: error.message });
        }
    }
}

export const setRedirect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
}